

import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";

const VideoCarouselSlider = (props) => {
  const [connected, setConnected] = useState(false);
  const [videoURL, setVideoURL] = useState('');
  const [rawMessage, setRawMessage] = useState('');
  const stompClientRef = useRef(null);
  const ID = props.id;

  const WEBSOCKET_URL = "https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/ws-video";
  const SUBSCRIBE_TOPIC = "/video/updates";

  // Fetch initial video URL
  const fetchVideoData = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/api/items/${ID}/video`);
      console.log("Initial video url:", response.data);
      setVideoURL(response.data);
    } catch (error) {
      console.error("Error fetching initial video:", error);
    }
  }, [ID]);

  // WebSocket Connection Effect
  useEffect(() => {
    const socket = new SockJS(WEBSOCKET_URL);
    const client = new Client({
      webSocketFactory: () => socket,
      debug: (str) => console.log("WebSocket Debug:", str),
      reconnectDelay: 5000,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
    });

    client.onConnect = () => {
      console.log("WebSocket connected");
      setConnected(true);
      stompClientRef.current = client;

      // Subscribe to video updates
      client.subscribe(SUBSCRIBE_TOPIC, (message) => {
        console.log("Received raw message:", message.body);
        setRawMessage(message.body);

        try {
          // Remove "Server received: " prefix if it exists
          const jsonStr = message.body.replace("Server received: ", "");
          const data = JSON.parse(jsonStr);
          
          // Check if it's a VIDEO_UPLOAD type message and has the correct structure
          if (data.type === "VIDEO_UPLOAD" && data.videoUrl && data.videoUrl.videoUrl) {
            const newVideoUrl = data.videoUrl.videoUrl;
            console.log("New video URL:", newVideoUrl);
            
            if (newVideoUrl !== videoURL) {
              setVideoURL(newVideoUrl);
            }
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
          console.log("Failed message content:", message.body);
        }
      });
    };

    client.onStompError = (frame) => {
      console.error("STOMP error:", frame.headers["message"]);
      setConnected(false);
    };

    client.activate();

    return () => {
      if (client) client.deactivate();
    };
  }, []);

  //muted for mute sound
  // Fetch initial video data
  useEffect(() => {
    fetchVideoData();
  }, [fetchVideoData]);

  return (
    <div className="video-carousel-container">
      {videoURL ? (
        <div className="video-slide">
          <video
            style={{width:"430px", height:"auto"}}
            src={videoURL}
            autoPlay={true}
            loop
    
            playsInline
            controls
            onError={(e) => {
              console.error("Error playing video:", e);
            }}
          />
          {!connected && (
            <div className="connection-status" style={{color: 'red'}}>
              WebSocket Disconnected
            </div>
          )}
        </div>
      ) : (
        <div className="no-videos">No videos available</div>
      )}

      {/* Debugging Section */}
      <div style={{marginTop: '20px', border: '1px solid #ccc', padding: '10px'}}>
        <h3>Debugging Information</h3>
        <p>Connection Status: {connected ? 'Connected' : 'Disconnected'}</p>
        <p>Raw Message: {rawMessage}</p>
        <p>Current Video URL: {videoURL}</p>
      </div>
    </div>
  );
};

export default VideoCarouselSlider;