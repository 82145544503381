// import React, { useState, useEffect } from "react";
// import Choco_Brownie_Shake from "../datas/brownie.jpeg";
// import Kit_Kat_Crunchy_Shake from "../datas/kitkat.jpeg";
// import Ultimate_Flavour_Bomb from "../datas/ultimatecopy.jpeg";
// import Oreo_Chocolate_Shake from "../datas/oreo.jpeg";

// const VerticalCarousel = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const images = [Choco_Brownie_Shake,Kit_Kat_Crunchy_Shake,Ultimate_Flavour_Bomb,Oreo_Chocolate_Shake]

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
//     }, 2000); // Change image every 2 seconds
//     return () => clearInterval(interval);
//   }, [images]);

//   return (
//     <div
//       style={{
//         height: "100%",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//         overflow: "hidden",
//       }}
//     >
//       {images.map((image, index) => (
//         <img
//           key={index}
//           src={image}
//           alt={`carousel-${index}`}
//           style={{
//             width: "100%",
//             height: "250px",
//             objectFit: "cover",
//             borderRadius: "10px",
//             opacity: currentIndex === index ? 1 : 0,
//             transition: "opacity 1s ease-in-out",
//             position: "absolute",
//           }}
//         />
//       ))}
//     </div>
//   );
// };

// export default VerticalCarousel;


import React, { useState, useEffect } from "react";
import axios from "axios";
import defImg from "../datas/shake_vector-removebg-preview.png";
const VerticalCarousel = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);

  const businessId = props.bId;
  // Fetch images from API
  useEffect(() => {
    const fetchImages = async () => {
      try {
        
        const response = await axios.get( `https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/api/items/business/${businessId}`); // Replace with your API endpoint
        const data =  response.data;
       
        // Assuming the API response has a format like: [{ imageLink: "url1" }, { imageLink: "url2" }]
        const imageLinks = data.map((item) => item.itemImageLink || defImg)
        
        setImages(imageLinks);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  // Handle automatic image rotation
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000); // Change image every 2 seconds
    return () => clearInterval(interval);
  }, [images]);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {images.length > 0 ? (
        images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`carousel-${index}`}
            style={{
              width: "100%",
              height: "250px",
              objectFit: "cover",
              borderRadius: "10px",
              opacity: currentIndex === index ? 1 : 0,
              transition: "opacity 1s ease-in-out",
              position: "absolute",
            }}
          />
        ))
      ) : (
        <p>Loading images...</p>
      )}
    </div>
  );
};

export default VerticalCarousel;


