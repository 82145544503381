import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "../node_modules/bootstrap-toggle/css/bootstrap-toggle.min.css";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Upload from "./Components/Upload";
import Home from "./Components/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import Business from "./Components/Menu";
import Cart from "./Components/Cart";
import NavBar from "./Components/Navbar";
import GetBusiness from "./Components/GetBusiness";
import UploadMenu from "./Components/UploadMenu";
import UserMenu from "./Components/UserMenu";
import CheckoutResponse from "./Components/CheckoutResponse";
import BusinessDashboard from "./Components/BusinessDashboard";
import FoodSimulation from "./Components/FoodSimulation";
import CurrentOrders from "./Components/CurrentOrders";
import DataForDateDash from "./charts/DataForDateDashboard";
import DailyDash from "./charts/DailyDash";
import AdvisorMenu from "./Components/AdvisorMenu";
import OrderList from "./Components/OrderList";

import { WebSocketProvider } from "./context/WebSocketContext";
// import {VideoWebSocketProvider} from './context/VideoContext';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <NavBar /> */}
        <div>
          <WebSocketProvider>
        
            <Routes>
              <Route path="/upload" element={<Upload />} />
              <Route path="/" element={<Home />} />
              <Route path="/usermenu" element={<UserMenu />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/business/:id" element={<Business />} />
              <Route path="/getbusiness" element={<GetBusiness />} />
              <Route path="/uploadmenu" element={<UploadMenu />} />
              <Route path="/checkoutresponse" element={<CheckoutResponse />} />
              <Route
                path="/business_dashboard"
                element={<BusinessDashboard />}
              />
              <Route path="/fs" element={<FoodSimulation />} />
              <Route path="/current-orders/:Bid" element={<CurrentOrders />} />
              <Route path="/date-dash" element={<DataForDateDash />} />
              <Route path="/daily-dash" element={<DailyDash />} />
              <Route path="/Ad-menu/:id" element={<AdvisorMenu />} />
              <Route path="/order-list/:id" element={<OrderList />} />
            </Routes>
      
          </WebSocketProvider>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
