import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { WebSocketContext } from "../context/WebSocketContext";
import { LuIndianRupee } from "react-icons/lu";

import { CardBody, Card } from "react-bootstrap";
import "./orderList.css";
import axios from "axios";
import Choco_Brownie_Shake from "../datas/brownie.jpeg";
import Kit_Kat_Crunchy_Shake from "../datas/kitkat.jpeg";
import Ultimate_Flavour_Bomb from "../datas/ultimatecopy.jpeg";
import Oreo_Chocolate_Shake from "../datas/oreo.jpeg";
import payimg from "../datas/payimage.png";
import defImg from "../datas/shake_vector-removebg-preview.png";

import confetti from "canvas-confetti";
import thank from "../datas/thankyou.png";

import menuimg from "../datas/menu3.png";
import { useParams } from "react-router-dom";
import VideoCarouselSlider from "./VideoCarouselSlider";
import VerticalCarousel from "./VerticalCarousel";

var pumpkin = confetti.shapeFromPath({
  path: "M449.4 142c-5 0-10 .3-15 1a183 183 0 0 0-66.9-19.1V87.5a17.5 17.5 0 1 0-35 0v36.4a183 183 0 0 0-67 19c-4.9-.6-9.9-1-14.8-1C170.3 142 105 219.6 105 315s65.3 173 145.7 173c5 0 10-.3 14.8-1a184.7 184.7 0 0 0 169 0c4.9.7 9.9 1 14.9 1 80.3 0 145.6-77.6 145.6-173s-65.3-173-145.7-173zm-220 138 27.4-40.4a11.6 11.6 0 0 1 16.4-2.7l54.7 40.3a11.3 11.3 0 0 1-7 20.3H239a11.3 11.3 0 0 1-9.6-17.5zM444 383.8l-43.7 17.5a17.7 17.7 0 0 1-13 0l-37.3-15-37.2 15a17.8 17.8 0 0 1-13 0L256 383.8a17.5 17.5 0 0 1 13-32.6l37.3 15 37.2-15c4.2-1.6 8.8-1.6 13 0l37.3 15 37.2-15a17.5 17.5 0 0 1 13 32.6zm17-86.3h-82a11.3 11.3 0 0 1-6.9-20.4l54.7-40.3a11.6 11.6 0 0 1 16.4 2.8l27.4 40.4a11.3 11.3 0 0 1-9.6 17.5z",
  matrix: [
    0.020491803278688523, 0, 0, 0.020491803278688523, -7.172131147540983,
    -5.9016393442622945,
  ],
});
// tree shape from https://thenounproject.com/icon/pine-tree-1471679/
var tree = confetti.shapeFromPath({
  path: "M120 240c-41,14 -91,18 -120,1 29,-10 57,-22 81,-40 -18,2 -37,3 -55,-3 25,-14 48,-30 66,-51 -11,5 -26,8 -45,7 20,-14 40,-30 57,-49 -13,1 -26,2 -38,-1 18,-11 35,-25 51,-43 -13,3 -24,5 -35,6 21,-19 40,-41 53,-67 14,26 32,48 54,67 -11,-1 -23,-3 -35,-6 15,18 32,32 51,43 -13,3 -26,2 -38,1 17,19 36,35 56,49 -19,1 -33,-2 -45,-7 19,21 42,37 67,51 -19,6 -37,5 -56,3 25,18 53,30 82,40 -30,17 -79,13 -120,-1l0 41 -31 0 0 -41z",
  matrix: [
    0.03597122302158273, 0, 0, 0.03597122302158273, -4.856115107913669,
    -5.071942446043165,
  ],
});
// heart shape from https://thenounproject.com/icon/heart-1545381/
var heart = confetti.shapeFromPath({
  path: "M167 72c19,-38 37,-56 75,-56 42,0 76,33 76,75 0,76 -76,151 -151,227 -76,-76 -151,-151 -151,-227 0,-42 33,-75 75,-75 38,0 57,18 76,56z",
  matrix: [
    0.03333333333333333, 0, 0, 0.03333333333333333, -5.566666666666666,
    -5.533333333333333,
  ],
});

var defaults = {
  scalar: 2,
  spread: 180,
  particleCount: 20,
  origin: { y: -0.1 },
  startVelocity: -35,
};

const OrderList = () => {
  const { stompClient, connected, connectWebSocket } =
    useContext(WebSocketContext); // Ensure connectWebSocket is available
  const [manu, setMenu] = useState([]);
  const [items, setItems] = useState([]);
  const [action, setAction] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [qrImage, setQrImage] = useState(null);
  const [orderNo, setOrderNo] = useState();
  const [showThankYou, setShowThankYou] = useState(false);
  const { id } = useParams();
  const [validVideos, setValidVideos] = useState([]);
  const [menuUrl, setMenuUrl] = useState("");
  const [showOrdering,setShowOrdering] = useState(false);

  // Fetch menu and valid videos
  const getMenu = async () => {
    try {
      const response = await axios.get(
        `https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/api/items/${id}/menu`
      );
      const data = response.data;
      setMenuUrl(data);
    } catch (e) {
      alert("unable to fetch menu -",e)
    }
    try {
      const response = await axios.get(
        `https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/api/items/business/${id}`
      );
      const data = response.data;
      const showOrder = data[0].business.showOrdering
      setShowOrdering(showOrder);
      
    } catch (e) {
      console.log("error getting the showorder-",e)
    }
  };

  // Initial menu fetch
  useEffect(() => {
    getMenu();
  }, [id]);

  useEffect(() => {
    if (action === "confirm") {
      const timer = setTimeout(() => {
        setShowThankYou(false);
      }, 5000); // 5 seconds
      return () => clearTimeout(timer); // Cleanup timeout
    }
  }, [action]);

 

  const shoot = () => {
    confetti({
      ...defaults,
      shapes: [pumpkin],
      colors: ["#ff9a00", "#ff7400", "#ff4d00"],
    });
    confetti({
      ...defaults,
      shapes: [tree],
      colors: ["#8d960f", "#be0f10", "#445404"],
    });
    confetti({
      ...defaults,
      shapes: [heart],
      colors: ["#f93963", "#a10864", "#ee0b93"],
    });
  };

  useEffect(() => {
    let subscription;

    const initializeSubscription = () => {
      if (connected && stompClient.current) {
        console.log("Subscribing to /topic/orderStatus...");

        subscription = stompClient.current.subscribe(
          "/topic/orderStatus",
          async (message) => {
            console.log("Received cart update message:", message.body);
            try {
              const data = JSON.parse(message.body);
              setItems(data.items); // Update items based on received data
              setTimeout(shoot, 0);
              setAction(data.action);
              setTotalAmount(data.totalPrice);
              setOrderNo(data.orderNo);
              console.log("Total Price:", data.totalPrice);

              // If action is "checkout", generate the QR code
              if (data.action === "checkout") {
                const qrCode = await generateQR(data.totalPrice);
                setQrImage(qrCode); // Set the QR code image
              }
              if (data.action === "confirm") {
                setShowThankYou("true");
              }
            } catch (error) {
              console.error("Error parsing message body:", error);
            }
          }
        );

        if (subscription) {
          console.log(`Successfully subscribed with id: ${subscription.id}`);
        } else {
          console.warn("Subscription failed.");
        }
      } else {
        console.log("WebSocket is not connected. Cannot subscribe.");
      }
    };

    // Initialize WebSocket connection if not already connected
    if (!connected) {
      console.log("WebSocket not connected. Attempting to connect...");
      connectWebSocket();
    }

    // Attempt to subscribe once connected
    if (connected) {
      initializeSubscription();
    }

    // Clean up the subscription when the component unmounts
    return () => {
      if (subscription) {
        console.log("Unsubscribing from /topic/orderStatus");
        subscription.unsubscribe();
      }
    };
  }, [connected, stompClient, connectWebSocket]);

  const generateQR = async (amount) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}generate-qr?upiData=upi:%2F%2Fpay%3Fpa%3Dpaytm.s1283c9%40pty%26pn%3DPaytm%26am%3D${amount}`,
        { responseType: "arraybuffer" } // Get the raw binary data
      );

      // Convert the byte file to a base64 image
      const base64Image = `data:image/png;base64,${btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      )}`;

      console.log("Generated QR Code:", base64Image);
      return base64Image; // Return the QR code as a Base64 image
    } catch (error) {
      console.error("Error generating QR code:", error);
      throw error;
    }
  };

  return (
    // <div
    //   className="main-orderlist"
    //   style={{
    //     display: "grid",
    //     gridTemplateColumns: "35% 40% 25%",
    //     gridGap: "0px",
    //     backgroundColor: "rgb(20,20,20)",
    //     height: "98vh",
    //   }}
    // >

    <div
  className="main-orderlist"
  style={{
    display: "grid",
    gridTemplateColumns: showOrdering ? "35% 40% 25%" : "40% 60%",
    gridGap: "0px",
    backgroundColor: "rgb(20,20,20)",
    height: "98vh",
  }}
>
      <div
        style={{
          margin: "0rem 0rem 0rem .7rem",

          borderRadius: "0px",
          width: "100%",
          height: "100vh",
          backgroundColor: "#f8f9fa",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={menuUrl}
          alt="Menu"
          style={{
            borderRadius: "0px",
            // maxWidth: "600px",
            padding: "0",
            width: "100%",
            height: "100vh",
            objectFit: "contain",
            objectPosition: "center",
          }}
        />
      </div>

      <div style={{ textAlign: "center" }}>
        {action === "checkout" ? (
          // Show QR Code and Total Price if QR is generated
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "0px",

              width: "100%",
              margin: "0 0 0 .5rem",
              height: "98vh",
              padding: "1rem",
            }}
          >
            <h2 style={{ padding: "1rem" }}>
              Please Pay: <LuIndianRupee /> {totalAmount}
            </h2>

            <img
              src={qrImage}
              alt="QR Code"
              style={{
                margin: ".5rem",
                marginTop: "0",
                width: "90%",
                height: "auto",
                borderRadius: "0px",
              }}
            />
            <img
              src={payimg}
              style={{
                width: "100%",
                bottom: "0",
                height: "auto",
              }}
            />
          </div>
        ) : showThankYou ? (
          // Show Thank You Card if showThankYou is true
          <div
            style={{
              margin: "auto auto auto auto",
              padding: "2rem",
              borderRadius: "0px",
              backgroundColor: "#f8f9fa",
              height: "100vh",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <img src={thank} style={{ width: "80%", height: "auto" }} />
            <h2 style={{ color: "#28a745", fontWeight: "bold" }}>
              Your Order No. - {orderNo?.toString().slice(-2)}
            </h2>
            <p style={{ fontSize: "16px", color: "#555" }}>
              Your order has been successfully confirmed.
            </p>
          </div>
        ) : (
          <Card
            className="dynamic-images"
            style={{
              margin: "0rem 0rem 1rem .5rem",
              width: "100%",
              height: "99vh",
              display: "grid",
              borderRadius: "0px",
              gridTemplateColumns:
                validVideos.length === 1
                  ? "1fr"
                  : "repeat(auto-fit, minmax(200px, 1fr))",
              gridTemplateRows:
                validVideos.length > 1
                  ? "repeat(auto-fit, minmax(200px, 1fr))"
                  : "1fr",
              gap: "10px",
              overflow: "hidden",
            }}
          >
            <CardBody className="p-0 h-100">
              <VideoCarouselSlider id={id} />
            </CardBody>
          </Card>
        )}
      </div>
      {showOrdering &&  <div>
      {items.length === 0 ? (
        <Card
          style={{
            width: "100%",
            borderRadius: "0px",
            height: "100vh",
            overflowY: "auto",
          }}
        >
          <Card.Header
            style={{
              backgroundColor: "#FFCB05",
              color: "white",
              fontWeight: "500",
              fontSize: "15px",
              paddingTop: ".25rem",
              borderRadius: "0",
              textAlign: "center",
            }}
          >
            <div>
              <h3>Order # {orderNo?.toString().slice(-3)}</h3>
            </div>
            <div>
              {" "}
              <h3>Total : Rs {totalAmount}</h3>
            </div>
          </Card.Header>
          <Card.Body>
            <VerticalCarousel bId={id} />
          </Card.Body>
        </Card>
      ) : (
        <Card
          style={{
            marginRight: "2rem",
            borderRadius: "0px",
            width: "100%",
            // border: "2px solid #eb7526",
            backgroundColor: "white",
            paddingTop: ".25rem",
            textAlign: "center",
            overflowY: "auto",
          }}
        >
          <Card.Header
            style={{
              backgroundColor: "#FFCB05",
              color: "white",
              fontWeight: "500",
              fontSize: "15px",
              borderRadius: "0",
            }}
          >
            <div>
              <h3>Order # {orderNo?.toString().slice(-2)}</h3>
            </div>
            <div>
              <h3>
                {" "}
                Total
                <LuIndianRupee /> {totalAmount}
              </h3>
            </div>
          </Card.Header>

          <div>
            {items.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  gap: "10px",
                  margin: "5px",
                  maxWidth: "100%",
                  borderBottom: ".5px solid gainsboro",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                }}
                className="menu_item"
              >
                <img
                  className="menu_image2"
                  src={item.itemImageLink || defImg} // Use defImg if itemImageLink is empty
                  alt={item.name}
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                    borderRadius: "5px",
                    flexShrink: 0,
                  }}
                />
             
                <div
                  className="menu_desc"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    padding: "0px",
                    marginRight: "1rem",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      marginRight: "1.5rem",
                      width: "100%",
                    }}
                  >
                    {item.name} (x {item.quantity})
                  </p>
                  <div>
                    <p
                      style={{
                        color: "#eb7526",
                        fontWeight: "bold",
                        fontSize: "14px",
                        margin: "0",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {item.attributeName
                        ? item.attributeName.charAt(0).toUpperCase() +
                          item.attributeName.slice(1)
                        : ""}{" "}
                      <LuIndianRupee /> {item.price * item.quantity}{" "}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Card>
      )}
    </div>}
    </div>
  );
};
export default OrderList;
