import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Modal, Button, Form, ButtonGroup } from "react-bootstrap";
import { LuIndianRupee } from "react-icons/lu";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { BsCurrencyRupee } from "react-icons/bs";
import { IoCart } from "react-icons/io5";
import GridLoader from "react-spinners/GridLoader";
import Card from "react-bootstrap/Card";
import { WebSocketContext } from "../context/WebSocketContext";


const AdvisorMenu = () => {
  const { stompClient, connected, connectWebSocket } =
    useContext(WebSocketContext);
  const [menu, setMenu] = useState([]);
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [showModalOrder, setShowModalOrder] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(true);
  const [online, setOnline] = useState(false);
  const [orderNo, setOrderNo] = useState();
  const navigate = useNavigate();
  const [qrImage,setQrImage] = useState(null);
  const [showImage,setShowImage] = useState(false);
  const [totalamount,setTotalamount] = useState(0);
   const [itemAttributes,setItemAttributes] = useState([])

   const [selectedAttributes, setSelectedAttributes] = useState({});
   const [attributeName,setAttributeName] = useState('');
  const { id } = useParams();

  
  


  const handleAttributeSelect = (itemId, attribute) => {
    setSelectedAttributes((prev) => ({
      ...prev,
      [itemId]: attribute,
    }));
    setAttributeName(attribute.sizeName);
  };

  const handleCheckout = async() => {
    if (!connected || !stompClient.current) {
      console.error("STOMP client is not connected yet");
      alert("WebSocket connection is not established. Please try again later.");
      return;
    }

    // Calculate the total price
    const totalPrice = items.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );

    console.log("Total price for checkout:", totalPrice);

    setTotalamount(totalPrice)
    const qrCode =  await generateQR(totalPrice);
  setQrImage(qrCode);
   setShowImage(true);


    try {
      stompClient.current.send(
        "/topic/orderStatus",
        {},
        JSON.stringify({
          action: "checkout",
          items,
          totalPrice,
          orderNo,
        })
      );

      console.log("Checkout details sent to WebSocket:", {
        action: "checkout",
        items,
        totalPrice,
      });


      setAttributeName("");
    } catch (error) {
      console.error("Error during WebSocket checkout:", error);
      alert("Checkout failed. Please try again.");
    }
  };
  const handlePhoneSubmit = () => {
    if (phoneNumber.length < 10) {
      alert("Please enter a valid mobile number.");
    } else {
      setShowModal(false);
      console.log("Phone number submitted. Connecting WebSocket...");
      connectWebSocket(); // This will initiate the connection and the reconnect logic if disconnected
    }
  };

  useEffect(() => {
    // Fetch menu
    const getMenu = async () => {
      try {
        const response = await axios.get(
          `https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/api/items/business/${id}`
        );

        setMenu(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching menu:", error);
        setLoading(false);
      }
    };

    // Fetch order number
    const fetchOrderNo = async () => {
      try {
        const response = await axios.get(
          `https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/api/orders/next-id`
        );
        console.log("order no - ", response);
        setOrderNo(response.data); // Set the fetched orderNo
      } catch (error) {
        console.error("Error fetching order number:", error);
      }
    };

    // Subscribe to WebSocket topic only when connected
    let subscription;
    if (connected && stompClient.current) {
      subscription = stompClient.current.subscribe(
        "/topic/orderUpdates", // Topic for order updates
        (message) => {
          try {
            const data = JSON.parse(message.body);
            if (data.orderNo) {
              setOrderNo(data.orderNo); // Update orderNo dynamically via WebSocket
            }
          } catch (error) {
            console.error("Error parsing WebSocket message:", error);
          }
        }
      );
    }

    // Fetch menu and orderNo on component mount
    getMenu();
    fetchOrderNo();

    // Cleanup subscription on component unmount
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [connected, stompClient]);

  // Function to refetch order number
  const refetchOrderNo = async () => {
    try {
      const response = await axios.get(
        `https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/api/orders/next-id`
      );
      setOrderNo(response.data); // Update the orderNo after success
    } catch (error) {
      console.error("Error refetching order number:", error);
    }
  };


  const handleAddItemClick = (item, attPrice) => {
    if (!connected || !stompClient.current) {
      console.error("STOMP client is not connected yet");
      return;
    }
    const itemId = item.id;
    const size =   selectedAttributes[item.id]?.sizeName || ""
  
    console.log("selected attributes - ",size);
    // Validate the price
    const validPrice = attPrice || item.price || 0;
  
    const updatedItems = [...items];

    const existingItemIndex = updatedItems.findIndex(
      (i) =>
        i.id === item.id &&
        i.price === validPrice  // Match based on attributeName too
    );
    
    if (existingItemIndex > -1) {

      updatedItems[existingItemIndex].quantity += 1;
    } else {
      // Add new item with validated price and attributeName
      updatedItems.push({
        ...item,
        quantity: 1,
        price: validPrice,
        attributeName: size || "", // Include attributeName if present
      });
    }
    
  
    const totalPrice = updatedItems.reduce(
      (sum, currentItem) =>
        sum + (currentItem.price || 0) * (currentItem.quantity || 0),
      0
    );
  
    setItems(updatedItems);
  
    stompClient.current.send(
      "/topic/orderStatus",
      {},
      JSON.stringify({
        items: updatedItems,
        action: "addItem",
        totalPrice,
        orderNo,
      })
    );

    setAttributeName("");
  };
  
  const handleMinusItemClick = (item, attPrice) => {
    if (!connected || !stompClient.current) {
      console.error("STOMP client is not connected yet");
      return;
    }
  
    // Validate the price
    const validPrice = attPrice || item.price || 0;
  
    const updatedItems = [...items];
    const existingItemIndex = updatedItems.findIndex(
      (i) => i.id === item.id && i.price === validPrice
    );
  
    if (existingItemIndex > -1) {
      updatedItems[existingItemIndex].quantity -= 1;
  
      if (updatedItems[existingItemIndex].quantity === 0) {
        // Remove the item completely if quantity is 0
        updatedItems.splice(existingItemIndex, 1);
      }
    }
  
    const totalPrice = updatedItems.reduce(
      (sum, currentItem) =>
        sum + (currentItem.price || 0) * (currentItem.quantity || 0),
      0
    );
  
    setItems(updatedItems);
  
    stompClient.current.send(
      "/topic/orderStatus",
      {},
      JSON.stringify({
        items: updatedItems,
        action: "minusItem",
        totalPrice,
        orderNo,
      })
    );
  };
  

  // const handleConfirmOrder = async () => {
  //   const orderDetailItems = items.map((item) => ({
  //     itemId: item.id,
  //     itemName: item.name,
  //     quantity: item.quantity || 0,
  //     price: item.price || 0,
  //     total: (item.price || 0) * (item.quantity || 0),
  //   }));
  
  //   const totalSales = orderDetailItems.reduce(
  //     (sum, item) => sum + (item.total || 0),
  //     0
  //   );
  
  //   const data = {
  //     order: {
  //       totalSales,
  //       total_cash: online ? 0 : totalSales,
  //       total_online: online ? totalSales : 0,
  //       total_expense_cash: 0,
  //       total_expense_online: 0,
  //       udhariGiven: 0,
  //       udhariReceived: 0,
  //       orderItems: orderDetailItems,
  //       orderDate: new Date().toISOString(),
  //     },
  //   };
  
  //   try {
  //     await axios.post(
  //       `https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/api/items/${id}/save-analyzed-data`,
  //       data
  //     );
  
  //     setItems([]);
  //     stompClient.current.send(
  //       "/topic/orderStatus",
  //       {},
  //       JSON.stringify({ items: [], action: "confirm", totalPrice: 0, orderNo })
  //     );
  
  //     // Refetch the order number after successful confirmation
  //     await refetchOrderNo();
  //     alert("Order Confirmed !");
  //   } catch (error) {
  //     console.error("Error during order confirmation:", error);
  //     alert("Order confirmation failed. Please try again.");
  //   }
  // };


  const handleConfirmOrder = async (isOnline) => {
    const orderDetailItems = items.map((item) => ({
      itemId: item.id,
      itemName: item.name,
      quantity: item.quantity || 0,
      price: item.price || 0,
      total: (item.price || 0) * (item.quantity || 0),
    }));
  
    const totalSales = orderDetailItems.reduce(
      (sum, item) => sum + (item.total || 0),
      0
    );
  
    const data = {
      order: {
        totalSales,
        total_cash: isOnline ? 0 : totalSales,
        total_online: isOnline ? totalSales : 0,
        total_expense_cash: 0,
        total_expense_online: 0,
        udhariGiven: 0,
        udhariReceived: 0,
        orderItems: orderDetailItems,
        orderDate: new Date().toISOString(),
      },
    };
  
    try {
      await axios.post(
        `https://api.awsconsultant.in/apis/salestracker-0.0.1-SNAPSHOT/api/items/${id}/save-analyzed-data`,
        data
      );
  
      setItems([]);
      stompClient.current.send(
        "/topic/orderStatus",
        {},
        JSON.stringify({ items: [], action: "confirm", totalPrice: 0, orderNo })
      );
  
      // Refetch the order number after successful confirmation
      await refetchOrderNo();
      alert("Order Confirmed!");
    } catch (error) {
      console.error("Error during order confirmation:", error);
      alert("Order confirmation failed. Please try again.");
    }
  };
  
   const generateQR = async (amount) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}generate-qr?upiData=upi:%2F%2Fpay%3Fpa%3Dpaytm.s1283c9%40pty%26pn%3DPaytm%26am%3D${amount}`,
          { responseType: "arraybuffer" } // Get the raw binary data
        );
  
        // Convert the byte file to a base64 image
        const base64Image = `data:image/png;base64,${btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        )}`;
  
        console.log("Generated QR Code:", base64Image);
        return base64Image; // Return the QR code as a Base64 image
      } catch (error) {
        console.error("Error generating QR code:", error);
        throw error;
      }
    };
  

  // const handleCardClick = async(data) => {
  //   setOnline(data); // Update the state
  //   setShowModalOrder(false); // Close the modal
  //   handleConfirmOrder(); // Call the API
  // };

  const handleCardClick = async (isOnline) => {
    setOnline(isOnline); // Update the state
    setShowModalOrder(false); // Close the modal
    handleConfirmOrder(isOnline); // Pass the value directly
  };

  if (loading) {
    return (
      <div className="loader-overlay">
        <GridLoader size={50} color="black" />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="loader-overlay">
        <GridLoader size={50} color="black" />
      </div>
    );
  }

  return (
    <>
   {showImage ? (
  <div style={{textAlign:"center",margin:"1rem",paddingTop:"1.5rem",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >
    <h2 style={{fontSize:"3rem"}}>Please Pay- <h2 style={{color:"#FF8000",fontSize:"3rem"}}><BsCurrencyRupee/>{totalamount}</h2></h2>
    {qrImage ? (
      <img
        src={qrImage}
        alt="QR Code"
        style={{
          margin: ".5rem",
          marginTop: "0",
          width: "auto",
          height: "25rem",
          borderRadius: "0px",
        }}
      />
      
    ) : (
      <p>Loading QR Code...</p>
    )}<Button  variant="warning"  onClick={() => {
      setShowImage(false)
      setShowModalOrder(true)
      }
    }>Confirm Payment</Button>
  </div>
) :
    <div style={{}}>
      <Modal show={showModal} onHide={() => setShowModal(true)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter Mobile Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your mobile number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handlePhoneSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {menu.length === 0 ? (
        <Card style={{ width: "20rem", margin: "20px" }}>
          <Card.Body>
            <Card.Title>No Menu To Show</Card.Title>
            <Card.Text>
              Please add a menu for this business to display items.
            </Card.Text>
          </Card.Body>
        </Card>
      ) : (

        <div className="menu_main">
        {menu.map((item) => (
          <div key={item.id} className="menu_item">
            <div className="menu_desc">
              <h6>{item.name}</h6>
      
              {item.itemAttributes && item.itemAttributes.length > 0 ? (
                // If item has attributes, show radio buttons for selection
                <div className="btnprice">
                  <Form>
                    {item.itemAttributes.map((attribute) => (
                      <Form.Check
                        key={attribute.attributeId}
                        type="radio"
                        id={`${item.id}-${attribute.attributeId}`}
                        label={`${attribute.sizeName} - ₹${attribute.price}`}
                        name={`attribute-${item.id}`}
                        onChange={() =>
                          handleAttributeSelect(item.id, attribute)
                        }
                        checked={
                          selectedAttributes[item.id]?.attributeId ===
                          attribute.attributeId
                        }
                      />
                    ))}
                  </Form>
                  <div className="btnprice">
                    {items.some(
                      (cartItem) =>
                        cartItem.id === item.id &&
                        (!selectedAttributes[item.id] || cartItem.price === selectedAttributes[item.id]?.price)
                    ) ? (
                      <div className="btnqtymenu">
                        <button
                          type="button"
                          className="plusminusbuttonmenu"
                          style={{padding:".8rem"}}
                          onClick={() =>{
                            setAttributeName( selectedAttributes[item.id]?.sizeName|| "")
                            handleAddItemClick(
                              item,
                              selectedAttributes[item.id]?.price || item.price
                            )}
                          }
                          disabled={!connected}
                        >
                          <FaPlus />
                        </button>
                        <span>
                          {items.find(
                            (cartItem) =>
                              cartItem.id === item.id &&
                              (!selectedAttributes[item.id] || cartItem.price === selectedAttributes[item.id]?.price)
                          )?.quantity || "Add"}
                        </span>
                        <button
                          type="button"
                          className="plusminusbuttonmenu"
                          style={{padding:".8rem"}}
                          onClick={() =>{
                           
                            handleMinusItemClick(
                              item,
                              selectedAttributes[item.id]?.price || item.price
                            )}
                          }
                          disabled={!connected}
                        >
                          <FaMinus />
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-dark"
                        
                        onClick={() =>
                          handleAddItemClick(
                            item,
                            selectedAttributes[item.id]?.price || item.price
                          )
                        }
                        disabled={!connected}
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                // If no attributes, show default price and add/remove functionality
                <div className="btnprice">
                  <h6>
                    <LuIndianRupee /> {item.price || 0}
                  </h6>
                  {items.some((cartItem) => cartItem.id === item.id) ? (
                    <div className="btnqtymenu">
                      <button
                        type="button"
                        className="plusminusbuttonmenu"
                        onClick={() => {
                          setAttributeName("");
                          handleAddItemClick(item, item.price)
                          
                        }}
                        disabled={!connected}
                      >
                        <FaPlus />
                      </button>
                      <span>
                        {items.find((cartItem) => cartItem.id === item.id)?.quantity ||
                          "Add"}
                      </span>
                      <button
                        type="button"
                        className="plusminusbuttonmenu"
                        
                        onClick={() => {
                          setAttributeName("");
                          handleMinusItemClick(item, item.price)}}
                        disabled={!connected}
                      >
                        <FaMinus />
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-dark"
                  
                      onClick={() => {
                        setAttributeName("");
                        handleAddItemClick(item, item.price)}}
                      disabled={!connected}
                    >
                      Add
                    </button>
                  )}
                </div>
              )}
            </div>
            <img
              className="menu_image"
              src={item.itemImageLink} // Use default image if no match
              alt={item.name}
            />
          </div>
        ))}
      </div>

      )}

      <div className="menu_footer">
        <Button
          variant="primary"
          style={{ margin: "20px" }}
          onClick={handleCheckout}
        >
          Generate QR <IoCart /> {items.length}
       </Button>
    

        {/* Modal with two cards */}
        <Modal
          show={showModalOrder}
          onHide={() => setShowModalOrder(false)}
          centered
        >
          <Modal.Header closeButton>
         <Modal.Title>Select Payment Method</Modal.Title>
          </Modal.Header>
           <div>
          
          <Modal.Body
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            {/* Online Card */}
            <Card
              bg="info"
              text="light"
              style={{
                width: "45%",
                padding: "20px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => handleCardClick(true)}
            >
              <Card.Body>
                <Card.Title>Online</Card.Title>
                <Card.Text>Click Here If Online Payment</Card.Text>
              </Card.Body>
            </Card>

            {/* Offline Card */}
            <Card
              bg="success"
              text="light"
              style={{
                width: "45%",
                padding: "20px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => handleCardClick(false)}
            >
              <Card.Body>
                <Card.Title>Cash</Card.Title>
                <Card.Text>Click Here If Cash Payment</Card.Text>
              </Card.Body>
            </Card>
          </Modal.Body>
          </div> 
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowModalOrder(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>}
    </>
  );
};

export default AdvisorMenu;

